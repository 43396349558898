import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import baseTheme from 'oxyrion-ui/lib/Themes/baseTheme';
import {
  Input,
  Button,
  Message,
  Label,
  FileInput,
  PageNavigator,
} from 'oxyrion-ui/lib';
import Icon from 'oxyrion-ui/lib/Icon';
import { connect } from '../../Store';
import { getIdFromProps, __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import SearchableSelect from '../../Components/SearchableSelect';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ColorPickerComponent from '../../Components/ColorPicker';
import AddNewColor from '../../Components/AddNewColor';
import ConfirmDialog from '../../Components/ConfirmDialog';
import {
  AnimatedFormMessageWrapper,
  DHeader,
  P,
  FlexBox,
} from '../../Components/ReusableComponents';
import TableV3 from '../../Components/TableV3';
import PageInfo from '../../Components/PageInfo';

import AdminAPI from '../../AdminAPI';
import API2 from '../../API2';

const ButtonsArea = styled.div`
  padding: ${rem(20)};
  justify-content: start;
  display: flex;
`;

const DeleteWrapper = styled.div`
  g {
    fill: ${({ theme }) => theme.color.danger};
  }
  text-align: right;
`;

const Span = styled.span`
  cursor: pointer;
`;

const ValueInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CustomInput = styled(Input)`
  max-width: ${rem(300)};
  margin: ${rem(10)} 0;
  padding: 0;
`;

const CustomInputSmall = styled(CustomInput)`
  max-width: ${rem(50)};
`;

const Percent = styled.div`
  margin-left: ${rem(30)};
  font-size: ${rem(15)};
`;

const BasicInfoWrapper = styled.div`
  display: flex;
  width: 90%;
  padding-left: ${rem(20)};
`;

const SearchableSelectWrapper = styled.div`
  width: ${rem(300)};
  padding-top: ${rem(11)};
  margin-right: ${rem(7)};
  margin-left: ${rem(30)};
`;

const PageWrapper = styled(FlexBox)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 0 2px 2px ${({ theme }) => theme.separatorColor};
  justify-content: space-between;
  padding: ${rem(10)} ${rem(20)};
  min-height: ${rem(15)};
  margin: auto;
  font-size: ${({ theme }) => theme.fontSize || rem(11)};
`;

const TableWrapper = styled.div`
  margin: ${({ small }) => (small ? 'unset' : 'auto')};
  min-height: ${({ small }) => (small ? 'unset' : '70vh')};
  width: 100%;
  margin-bottom: ${({ showPager }) => (showPager ? rem(55) : 0)};
`;

const acceptedMimeTypes = ['image/png'];

class ColourCardDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteLoading: false,
      showConfirmDialog: false,
      showSaveDialog: false,
      saveLoading: false,
      loadingShade: false,
      nameError: false,
      productError: false,
      toningSystemError: false,
      // saveDisabled: true,
      shadeToEdit: undefined,
      file: { fileValue: '', file: null, fileError: false },
      colorTypes: [],
      shades: [],
      colourCard: {
        price: {
          amount: 0,
          currency: 'EUR',
        },
      },
      showPager: true,
      limit: 100,
      offset: 0,
      total: 0,
      nextOffset: 0,
    };
  }

  async componentWillMount() {
    const id = getIdFromProps(this.props);
    const { offset, limit } = this.state;

    if (id !== 'new') {
      await this.fetchColourCardData(id);
    }

    const params = Object.assign({}, { limit, offset });

    await this.fetchShadesData(params);

    try {
      const result = await AdminAPI.getColorCategoriesTreeAction();
      const bases = await AdminAPI.getCodelist('base');

      this.setState({
        colorTypes: result.categories[0].items || [],
        bases: bases.codelist || [],
      });
    } catch (e) {
      console.log(e);
    }
  }

  fetchProducts(query) {
    try {
      return API2.getProductsAction(this.props.firm, {
        q: query,
        limit: 10,
      }).then(res => {
        return res.products.map(item => ({
          value: item._id,
          label: item.Description,
          product: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }
  fetchToningSystems(query) {
    try {
      return API2.getToningSystemsAction(this.props.firm, {
        q: query,
        limit: 10,
      }).then(res => {
        return res.systems.map(item => ({
          value: item._id,
          label: item.name,
          system: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchShadesData(params = {}) {
    const id = getIdFromProps(this.props);
    if (id === 'new') {
      return;
    }

    const { firm } = this.props;
    const { colourCard } = this.state;
    try {
      this.setState({ loading: true });
      const shadesData = await API2.getColorcardShadesAction(firm, id, params);

      this.setState({
        limit: shadesData.limit,
        offset: shadesData.offset,
        total: shadesData.total,
        nextOffset: shadesData.next_offset,
        colourCard,
        shades: shadesData.shades,
        loading: false,
      });
    } catch (e) {
      switch (e.response.status) {
        case 404:
          this.setState({
            error: __('Otiene sa nenašli'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async fetchColourCardData(id) {
    const { firm } = this.props;
    try {
      this.setState({ loading: true });
      const colourCard = await API2.getColorcardAction(firm, id, {
        sendShades: false,
      });

      if (colourCard.system_id) {
        const toningSystem = await API2.getToningSystemAction(
          firm,
          colourCard.system_id,
        );

        colourCard.toningSystem = {
          value: toningSystem._id,
          label: toningSystem.name,
          system: toningSystem,
        };
      }

      if (colourCard.nav_product_id) {
        const product = await API2.getProductAction(
          firm,
          colourCard.nav_product_id,
        );
        const price = product.Unit_Price;
        colourCard.product = {
          value: product._id,
          label: product.Description,
          product,
        };

        colourCard.price = price;
      } else {
        colourCard.price = {
          amount: 0,
          currency: 'EUR',
        };
      }

      let file = { fileValue: '', file: null, fileError: false };

      if (colourCard.texture_image_path) {
        file = {
          file: {
            fullPath: colourCard.texture_image_path,
            name: colourCard.texture_image_name,
          },
          fileValue: colourCard.texture_image_name,
        };
      }

      this.setState({
        colourCard,
        shades: colourCard.shades,
        file,
        loading: false,
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie vzorkovníka nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Vzorkovník sa nenašiel'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  checkData() {
    const { colourCard } = this.state;
    let { nameError, toningSystemError } = this.state;
    let valid = true;

    if (colourCard.name.length < 1) {
      valid = false;
      nameError = true;
    }
    if (!colourCard.toningSystem) {
      valid = false;
      toningSystemError = true;
    }
    if (!valid) {
      this.setState({
        toningSystemError,
        nameError,
      });
    }

    return valid;
  }

  async saveColourCard(forced = false) {
    let { colourCard } = this.state;
    const { file } = this.state;
    const { history, firm } = this.props;

    this.setState({
      saveLoading: true,
      productError: false,
      nameError: false,
      success: undefined,
      error: false,
    });
    if (this.checkData()) {
      try {
        const product = Object.assign({}, colourCard.product);
        const data = {
          name: colourCard.name,
          // shades: colourCard.shades.map(s =>
          //   Object.assign(s, {
          //     color_base_name: s.color_base_name || s.color_code,
          //   }),
          // ),
          system_id: colourCard.toningSystem.value,
          nav_product_id:
            colourCard &&
            colourCard.product &&
            colourCard.product.product &&
            colourCard.product.product._id,
        };
        if (file && file.file && file.file.fullPath) {
          Object.assign(data, {
            texture_image_path: file.file.fullPath || '',
            texture_image_name: file.file.name || '',
          });
        } else {
          Object.assign(data, {
            texture_image_path: null,
            texture_image_name: null,
          });
        }
        if (colourCard._id) {
          await API2.putColorcardAction(firm, colourCard._id, { data, forced });
        } else {
          const result = await API2.postColorcardAction(firm, {
            body: Object.assign(data, {
              shades: [],
            }),
          });
          if (result && getIdFromProps(this.props) === 'new') {
            history.push(`/${firm}/colourCards/${result._id}`);
            colourCard = result;
            if (product && product.value) {
              colourCard.product = product;
            }
          }
        }
        this.setState({
          // saveDisabled: true,
          success: __('Vzornkovník bol úspešne uložený'),
          colourCard,
        });
      } catch (e) {
        console.log(e);
        if (e.response.status === 409) {
          this.setState({ showForceSaveDialog: true });
        } else {
          this.setState({
            error: __('Pri ukladaní dát sa vyskytla chyba'),
          });
        }
      }
    }
    this.setState({
      saveLoading: false,
    });
  }

  async deleteColourCard(forced = false) {
    const { colourCard } = this.state;
    const { history, firm } = this.props;
    this.setState({
      deleteLoading: true,
    });
    try {
      await API2.deleteColorcardAction(firm, colourCard._id, { forced });
      history.push(`/${firm}/colourCards/`);
    } catch (e) {
      if (e.response.status === 409) {
        this.setState({
          showConfirmDialog: false,
          showForceDeleteDialog: true,
        });
      } else {
        this.setState({
          deleteError: __('Pri vymazávaní dát sa vyskytla chyba'),
        });
      }
    }
    this.setState({
      deleteLoading: false,
    });
  }

  async addNew(newColor) {
    const { shadeToEdit, limit, offset } = this.state;
    const color = {
      color_code: newColor.id,
      color_name: newColor.color_name,
      color_base_name: newColor.id,
      color: {
        R: Number(newColor.RGB_R),
        G: Number(newColor.RGB_G),
        B: Number(newColor.RGB_B),
      },
      basecodes: newColor.basecodes,
      color_group: newColor.colorGroup,
      image_path: newColor.image_path,
      image_name: newColor.image_name,
      color_name_translations: newColor.translations,
    };

    if (shadeToEdit && shadeToEdit.formula_import_id) {
      color.formula_import_id = shadeToEdit.formula_import_id;
    }

    if (shadeToEdit && shadeToEdit.product_innovatint_id) {
      color.product_innovatint_id = shadeToEdit.product_innovatint_id;
    }

    if (shadeToEdit && shadeToEdit.color_code) {
      try {
        this.setState({ loadingShade: true, success: null, error: null });

        const { items } = await API2.putColorcardShadeAction(
          this.props.firm,
          this.state.colourCard._id,
          encodeURIComponent(shadeToEdit.color_code),
          { body: color, limit, offset },
        );

        this.setState({
          shades: items,
          shadeToEdit: undefined,
          loadingShade: false,
          success: __('Odtieň bol úspešne upravený'),
        });
      } catch (e) {
        this.setState({
          error: __('Pri upravovaní sa vyskytla chyba'),
          loadingShade: false,
          shadeToEdit: color,
        });
      }
    } else {
      try {
        this.setState({ loadingShade: true, success: null, error: null });

        const { items } = await API2.postColorcardShadeAction(
          this.props.firm,
          this.state.colourCard._id,
          { body: color, limit, offset },
        );

        this.setState({
          shades: items,
          shadeToEdit: undefined,
          loadingShade: false,
          success: __('Odtieň bol úspešne pridaný'),
        });
      } catch (e) {
        if (e.response.status === 409) {
          this.setState({
            error: __('Odtieň s týmto kódom už existuje'),
            loadingShade: false,
            shadeToEdit: color,
          });
        } else {
          this.setState({
            error: __('Pri pridávaní sa vyskytla chyba'),
            loadingShade: false,
            shadeToEdit: color,
          });
        }
      }
    }
  }

  async toggleDeleteColor(index) {
    const { shades, limit, offset } = this.state;
    const shadeToDelete = shades[index];

    if (
      !window.confirm(`${__('Odstrániť odtieň')}: ${shadeToDelete.color_code}`)
    ) {
      return;
    }

    try {
      this.setState({ loading: true });

      const { items } = await API2.deleteColorcardShadeAction(
        this.props.firm,
        this.state.colourCard._id,
        shadeToDelete.color_code,
        {
          limit,
          offset,
        },
      );

      this.setState({
        shades: items,
        loading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Pri odstraňovaní sa vyskytla chyba'),
        loading: false,
      });
    }
  }

  editField(index) {
    const { shades } = this.state;
    const shadeToEdit = shades[index];
    // shades.splice(index, 1);

    this.setState({ shades, shadeToEdit });
  }

  showConfirmDialog() {
    this.setState({
      showConfirmDialog: !this.state.showConfirmDialog,
    });
  }

  showSaveDialog() {
    this.setState({
      showSaveDialog: !this.state.showSaveDialog,
    });
  }

  changeValue(value, field) {
    const { colourCard } = this.state;

    if (field === 'name') {
      colourCard.name = value;
    } else if (field === 'toning_system') {
      colourCard.toningSystem = value;
    } else if (field === 'product') {
      colourCard.product = value;
      if (value) {
        colourCard.price.amount = value.product.Unit_Price.amount
          ? value.product.Unit_Price.amount
          : 0;
      } else {
        colourCard.price.amount = 0;
      }
    } else if (field === 'price') {
      colourCard.price.amount = value;
    }

    this.setState({
      colourCard,
      toningSystemError: false,
      // saveDisabled: false,
    });
  }

  discardEdit() {
    const { shades } = this.state;

    this.setState({
      shades,
      shadeToEdit: undefined,
    });
  }

  createColumns() {
    // const { colorTypes } = this.state;

    // const categories = colorTypes.map(c => c.categories).flat();

    return [
      {
        accessor: 'color_code',
        Header: () => <DHeader>{__('ID / kód farby')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
      },
      {
        accessor: 'basecodes',
        Header: () => <DHeader>{__('Bázy')}</DHeader>,
        Cell: props => {
          return (
            <P>
              {props.value && props.value.length > 0
                ? props.value.join(',')
                : '-'}
            </P>
          );
        },
        maxWidth: 300,
      },
      {
        accessor: 'color_name',
        Header: () => <DHeader>{__('Názov odtieňa')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
        maxWidth: 300,
      },
      {
        accessor: 'external_product',
        Header: () => <DHeader>{__('Externý produkt')}</DHeader>,
        Cell: props => {
          return <P>{props.value ? props.value.name : ''}</P>;
        },
        maxWidth: 300,
      },
      {
        accessor: 'color',
        Header: () => <DHeader>{__('R')}</DHeader>,
        Cell: props => {
          return <P>{props.value.R}</P>;
        },
        maxWidth: 50,
      },
      {
        accessor: 'color',
        Header: () => <DHeader>{__('G')}</DHeader>,
        Cell: props => {
          return <P>{props.value.G}</P>;
        },
        maxWidth: 50,
      },

      {
        accessor: 'color',
        Header: () => <DHeader>{__('B')}</DHeader>,
        Cell: props => {
          return <P>{props.value.B}</P>;
        },
        maxWidth: 50,
      },
      {
        accessor: 'color',
        Header: () => <DHeader>{__('')}</DHeader>,
        Cell: props => {
          return (
            <P style={{ position: 'relative' }}>
              <ColorPickerComponent
                imgColor={props.original.image_path}
                img={
                  this.state.file &&
                  this.state.file.file &&
                  this.state.file.file.fullPath
                }
                color={`rgba(${props.value.R},${props.value.G},${props.value.B},1)`}
              />
            </P>
          );
        },
        maxWidth: 58,
      },
      // {
      //   accessor: 'color_group',
      //   Header: () => <DHeader>{__('Farebná skupina')}</DHeader>,
      //   Cell: props => {
      //     return (
      //       <P>
      //         {categories.find(c => c._id === props.value) &&
      //           categories.find(c => c._id === props.value).name}
      //       </P>
      //     );
      //   },
      //   maxWidth: 150,
      // },
      {
        Header: () => <DHeader>{__('')}</DHeader>,
        Cell: props => {
          return (
            <P>
              <DeleteWrapper>
                <Span onClick={() => this.editField(props.row._index)}>
                  <Icon name="edit" size="l" />
                </Span>

                <Span
                  onClick={() => {
                    this.toggleDeleteColor(props.row._index);
                  }}
                >
                  <Icon name="close" color={baseTheme.color.danger} size="m" />
                </Span>
              </DeleteWrapper>
            </P>
          );
        },
        maxWidth: 100,
      },
    ];
  }

  async fileOnChange(filesInput) {
    if (filesInput.length) {
      const f = filesInput[0];
      if (!acceptedMimeTypes.includes(f.type)) {
        this.setState({ error: __('Zlý formát súboru.'), loading: false });
      } else {
        try {
          const result = await API2.postColorCardTextureImageAction(
            this.props.firm,
            {
              image: f,
            },
          );

          const file = {
            fileValue: result.name,
            file: result,
            fileError: false,
            errors: [],
          };
          this.setState({
            file,
            // saveDisabled: false,
          });
        } catch (e) {
          this.setState({
            error: __('Súbor sa nepodarilo uložiť'),
          });
        }
      }
    }
  }

  async removeFile() {
    const { file } = this.state;
    try {
      await API2.deleteColorCardImageAction(
        encodeURIComponent(file.file.fullPath),
        this.props.firm,
      );

      this.setState({
        file: { fileValue: '', file: null, fileError: false },
        // saveDisabled: false,
      });

      this.saveColourCard();
    } catch (e) {
      this.setState({
        error: __('Nepodarilo sa odstrániť súbor'),
      });
    }
  }

  renderControlBar(name) {
    const { history } = this.props;
    return (
      <React.Fragment>
        <ControllBar
          history={history}
          name={`${__('Vzorkovník')} - ${name || 'Nový'}`}
        >
          <ControlBarButton
            small
            danger
            onClick={() => this.showConfirmDialog()}
            icon="delete"
          >
            {__('Odstrániť')}
          </ControlBarButton>
        </ControllBar>
      </React.Fragment>
    );
  }

  // eslint-disable-next-line react/sort-comp
  onPageClick(page) {
    const { limit } = this.state;
    this.fetchShadesData({
      offset: (page - 1) * limit,
    });
  }

  onIncrease() {
    const { nextOffset } = this.state;
    this.fetchShadesData({
      offset: nextOffset,
    });
  }

  onDecrease() {
    const { limit, offset } = this.state;
    this.fetchShadesData({
      offset: offset - limit,
    });
  }

  renderPageNavigation(limit, offset, total) {
    return (
      <PageWrapper>
        <PageInfo offset={offset} limit={limit} total={total} />
        <PageNavigator
          activePage={offset / limit + 1}
          end={total / limit + 1}
          onPageClick={page => this.onPageClick(page)}
          onDecrease={() => this.onDecrease()}
          onIncrease={() => this.onIncrease()}
        />
      </PageWrapper>
    );
  }

  render() {
    const { firm } = this.props;
    const {
      deleteLoading,
      loading,
      showConfirmDialog,
      error,
      success,
      deleteError,
      colourCard,
      showSaveDialog,
      saveLoading,
      nameError,
      productError,
      toningSystemError,
      // saveDisabled,
      shadeToEdit,
      showForceSaveDialog,
      showForceDeleteDialog,
      file,
      colorTypes,
      bases,
      showPager,
      limit,
      offset,
      total,
      shades,
      loadingShade,
    } = this.state;

    console.log('shadeToEdit--', shadeToEdit);

    return (
      <React.Fragment>
        {this.renderControlBar(colourCard.name)}
        <PopUpWrapper
          display={showConfirmDialog}
          small
          onClose={() => this.showConfirmDialog()}
        >
          <ConfirmDialog
            message={__('Prajete si odstániť vzorkovník ?')}
            onDismiss={() => this.showConfirmDialog()}
            onConfirm={() => this.deleteColourCard()}
            // dismissMessage={error}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showForceSaveDialog}
          small
          onClose={() => this.setState({ showForceSaveDialog: false })}
        >
          <ConfirmDialog
            message={__(
              'Vzorkovník sa používa v receptúre. Napriek tomu ho chcete upraviť ?',
            )}
            onDismiss={() => this.setState({ showForceSaveDialog: false })}
            onConfirm={() => {
              this.setState({ showForceSaveDialog: false });
              this.saveColourCard(true);
            }}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showForceDeleteDialog}
          small
          onClose={() => this.setState({ showForceDeleteDialog: false })}
        >
          <ConfirmDialog
            message={__(
              'Vzorkovník sa používa v receptúre. Napriek tomu ho chcete vymazať ?',
            )}
            onDismiss={() => this.setState({ showForceDeleteDialog: false })}
            loading={deleteLoading}
            onConfirm={() => {
              this.deleteColourCard(true);
            }}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showSaveDialog}
          small
          onClose={() => this.showSaveDialog()}
        >
          <ConfirmDialog
            message={__('Prajete si uložiť zmeny ?')}
            onDismiss={() => this.showSaveDialog()}
            onConfirm={() => this.saveColourCard()}
            dismissMessage={error}
            loading={saveLoading}
          />
        </PopUpWrapper>

        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        <BasicInfoWrapper>
          <CustomInput
            placeholder={__('Názov')}
            right
            error={nameError}
            value={colourCard.name}
            onChange={e => this.changeValue(e.target.value, 'name')}
          />
          <SearchableSelectWrapper>
            <SearchableSelect
              value={colourCard.toningSystem}
              loadOptions={query => this.fetchToningSystems(query)}
              placeholder={__('Vyhľadať tónovací systém')}
              name={colourCard.toningSystem}
              error={toningSystemError}
              disabled={getIdFromProps(this.props) !== 'new'}
              handleOnChange={e => this.changeValue(e, 'toning_system')}
            />
          </SearchableSelectWrapper>
          <SearchableSelectWrapper>
            <SearchableSelect
              value={colourCard.product}
              loadOptions={query => this.fetchProducts(query)}
              placeholder={__('Vyhľadať produkt')}
              name={colourCard.product}
              error={productError}
              handleOnChange={e => this.changeValue(e, 'product')}
            />
          </SearchableSelectWrapper>

          <ValueInputWrapper>
            <CustomInputSmall
              type="number"
              disabled
              value={colourCard.price && colourCard.price.amount}
              onChange={e => {
                this.changeValue(e.target.value, 'price');
              }}
            />
            <Percent> {firm === 'SK' ? '€' : 'CZK'}</Percent>
          </ValueInputWrapper>
          <ValueInputWrapper style={{ marginLeft: rem(40) }}>
            <Label>{__('Textúra')}</Label>
            <FileInput
              style={{ minWidth: rem(200) }}
              item={file.fileValue}
              onChange={e => this.fileOnChange(e.target.files)}
              onRemoveFile={() => this.removeFile()}
            />
          </ValueInputWrapper>
          <Label>{__('png')}</Label>
        </BasicInfoWrapper>

        {getIdFromProps(this.props) !== 'new' && (
          <TableWrapper small={false} showPager={showPager}>
            <TableV3
              columns={this.createColumns()}
              minWidth={10}
              loading={loading}
              data={shades}
              getTdProps={() => {
                return { style: { padding: 0, margin: 'auto' } };
              }}
              getTheadThProps={(state, rowInfo, column) => {
                if (column && column.sortable) {
                  return {
                    onClick: e => {
                      this.sortByV2(e.target.innerHTML);
                    },
                  };
                }
                return {};
              }}
              className="-highlight -striped"
              offset={450}
            />

            <AddNewColor
              onSubmit={o => this.addNew(o)}
              shadeToEdit={shadeToEdit}
              colorTypes={colorTypes}
              discard={() => this.discardEdit()}
              firm={this.props.firm}
              bases={bases}
              buttonLoading={loadingShade}
              shades={shades}
            />

            {showPager ? this.renderPageNavigation(limit, offset, total) : ''}
          </TableWrapper>
        )}
        <ButtonsArea>
          <Button
            primary
            loading={saveLoading}
            // disabled={saveDisabled}
            onClick={() => this.saveColourCard()}
          >
            {__('Uložiť')}
          </Button>
        </ButtonsArea>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ColourCardDetail);
